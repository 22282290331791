import {React, useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.css';
import Webcam from "react-webcam";
import './passport.css';




const Idproofback = () => {
    const webcamRef = useRef(null);
    const [idimageSrc, setImageSrc] = useState(null);
	const [uploading, setUploading] = useState('');
    const navigate = useNavigate();
    const brandname = localStorage.getItem('brandname');
    const id_image = localStorage.getItem('id_image_back');
    const setdocumenttitle = localStorage.getItem('setdocumenttitle');
    const address_proof = localStorage.getItem('address_proof');
    const partial_page = localStorage.getItem('partial_page');
    const kyctype = localStorage.getItem('kyctype');
    const [isMobile, setIsMobile] = useState(false);

    const [info, setinfo] = useState({
        id: '',
        image: ''
    });

    let textMsg = '';

    const [disabledbtn, setdisabled] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selvisible, setSelvisible] = useState(true);
    
    const [btn, setbtn] = useState("btn btn-primary");
    const [btntxt, setbtntxt] = useState("Continue");
    const [btnicon, setbtnicon] = useState("fa-solid fa-arrow-right");

    const checkIfMobile = () => {
        if (window.innerWidth <= 768) {
          return true; 
        }
        return false;
      };
    
      // Funzione per aggiornare lo stato isMobile
      const updateMedia = () => {
        setIsMobile(checkIfMobile());
      };
    
      // Aggiungi listener per il resize della finestra
      useEffect(() => {
        updateMedia();
        window.addEventListener('resize', updateMedia);
        return () => {
          window.removeEventListener('resize', updateMedia);
        };
      }, []);

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        localStorage.setItem('Ip-Adrees', res.data.ip);
    };

    const retake = () => {
        setImageSrc(null);
        setSelvisible(true);
        setVisible(false);
        setdisabled(false);
        setbtntxt("Continue");
        setbtn("btn btn-primary");
        setbtnicon("fa-solid fa-arrow-right");
    }

    const capture = () => {
         const idimageSrc = webcamRef.current.getScreenshot({width: 720, height: 1280});
        setImageSrc(imageSrc);
        setinfo({
            id: setdocumenttitle,
            image: imageSrc
        });
        setSelvisible(false);
        setVisible(true);
    };
    // const nextPage = () => {
    //     navigate('/proof');
    // };
    const handleUpload = async (event) => {
		setUploading('Uploading...');
		event.currentTarget.disabled = true;
        setbtntxt("");
        setbtn("btn btn-success");
        setbtnicon("fa-solid fa-check");
        setdisabled(true);
        setTimeout(() => { setdisabled(false); }, 10000);
        await saveinStorage();
    };

    const saveinStorage = async () => {
        let datas = JSON.stringify({
            "uuid": localStorage.getItem('uuid'),
            "image": info.image,
            "type": setdocumenttitle,
        });

        let config = {
            method: 'post',
            mode: 'cors',
            url: 'https://kyc2.codegotech.com/save_image',
            headers: {
                'Content-Type': 'application/json'
            },
            data: datas
        };

        axios.request(config).then((response) => {
            if (response.status === 200) {
                if (response.data.status === 1) {
                    if (kyctype === 'partial' && partial_page === 'ID') {
                        let data = JSON.stringify({
                            "type": setdocumenttitle,
                            "uuid": localStorage.getItem('uuid'),
                            "kyctype": kyctype,
                            "partial_page": partial_page,
                            "userAgent": localStorage.getItem('userAgent'),
                            "ip_address": localStorage.getItem('Ip-Adrees'),
                            "passportPhoto_back": response.data.image,
                            "passportPhoto": localStorage.setItem('passportPhoto'),
                        });

                        let config = {
                            method: 'post',
                            mode: 'cors',
                            url: 'https://kyc2.codegotech.com/send_userdata',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        axios.request(config).then((response) => {
                            if (response.status === 200) {
                                localStorage.removeItem('userAgent');
                                localStorage.removeItem('Ip-Adrees');
                                localStorage.removeItem('passportphoto');
                                localStorage.removeItem('uuid');
                                navigate('/process');
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    } else {
                        localStorage.setItem('passportPhoto_back', response.data.image);
                        if (address_proof === '1') {
                            navigate('/proof');
                        } else {
                            navigate('/Recognition');
                        }
                    }
                } else {
                    setVisible(false);
                    setSelvisible(true);
                    let html = 'Please re-upload passport image.';
                    let mrz_data_element = document.getElementById('mrz-data');
                    mrz_data_element.innerHTML = html;
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    useEffect(() => {
        let uuids = localStorage.getItem('uuid');
        if (uuids === null) {
            navigate('/');
        }
        getData();
    }, []);

    return (
        <div className="container-fluid">
            {!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}
      {isMobile && (
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="row top_div">
                        <div className="card">
                            <div className="card-header text-center">
                                <div className="title">{setdocumenttitle} Picture</div>
                                <p>Back Side</p>
                                <small>Make sure that all the information on the photo is visible and easy to read, and that all corners are visible</small>
                            </div>
                            <div className="card-body text-center">
                                <div className="bg_camera proof">
                                    {idimageSrc ? (
                                        <div className="box-btn">
                                            <img src={idimageSrc} alt="Captured" className="img-fluid" />
											<p style = {{color: "red"}} >{uploading}</p>
                                            <button onClick={() => setImageSrc(null)} className="btn_retake">Retake</button>
                                            <button onClick={handleUpload} disabled={!idimageSrc} className="btn_retake upload">Submit</button>
                                        </div>
                                    ) : (
                                        <>
                                            <Webcam
                                                 audio={false}
                                                 ref={webcamRef}
                                                 screenshotFormat="image/jpg"
                                                 videoConstraints={{
                                                    height: 720, 
                                                    width: 1280,
                                                    facingMode: "environment"                     
                                                 }}
                                                 className="webcam proof"
                                                 width={1280}
                                                 height={720}
												 style = {{width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center"}}
                                            />
                                            <div className="circle_btn">
                                                <button onClick={capture} className="camera"></button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="passport-photo">
                                    <div id="row">
                                        <div className="example_passport">
                                            <p className="text-danger">{textMsg}</p>
                                            <p>Example {setdocumenttitle} Picture</p>
                                            <img src={id_image} className="example_pic" alt="passport" />
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row footer">
                        <div className="collect-steps_1">{brandname}</div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}
export default Idproofback;
