import {React,  useState ,useEffect,useRef} from "react";
import { useNavigate  } from "react-router-dom";
import   axios from "axios";
import Webcam from "react-webcam";
import './passport.css';
import 'bootstrap/dist/css/bootstrap.css';


const ProffAdress = () => {
	const inputRef = useRef(null);
    const brandname = localStorage.getItem('brandname');
    const webcamRef = useRef(null);
    const [idimageSrc, setImageSrc] = useState(null);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

	const [uploading, setUploading] = useState('');
	let photo = '';
	const [info , setinfo]=useState({
		image : ''
	});
	
	const partial_page = localStorage.getItem('partial_page');
	const kyctype = localStorage.getItem('kyctype');
	let textMsg = '';

    const [disabledbtn, setdisabled] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selvisible, setSelvisible] = useState(true);
    // const setdocumenttitle = localStorage.getItem('setdocumenttitle');
    const address_proof = localStorage.getItem('address_proof');
    const [btn, setbtn] = useState("btn btn-primary");
    const [btntxt, setbtntxt] = useState("Continue");
    const [btnicon, setbtnicon] = useState("fa-solid fa-arrow-right");
		const getData = async () => {
			const res = await axios.get("https://api.ipify.org/?format=json");
			
			localStorage.setItem('Ip-Adrees', res.data.ip);
		};
        const checkIfMobile = () => {
            if (window.innerWidth <= 768) {
              return true; 
            }
            return false;
          };

          const updateMedia = () => {
            setIsMobile(checkIfMobile());
          };
        
        
          useEffect(() => {
            updateMedia();
            window.addEventListener('resize', updateMedia);
            return () => {
              window.removeEventListener('resize', updateMedia);
            };
          }, []);
		const retake= ()=>{
            // setinfo({
            //     id: '',
            //     image: idimageSrc
            // });
			// setFile('');
			setSelvisible(true);
			setVisible(false);
			setdisabled(false);
			
			setbtntxt("Continue");
			setbtn("btn btn-primary");
			setbtnicon("fa-solid fa-arrow-right");
		}
        const capture = () => {
            const idimageSrc = webcamRef.current.getScreenshot({height: 1920, width: 1080});

        setImageSrc(idimageSrc);
            setinfo({
                id: kyctype,
                image: idimageSrc
            });
            setSelvisible(false);
            setVisible(true);
        };
        // const nextPage = () => {
        //     navigate('/Recognition');
        // };
        const handleUpload = async (event) => {
			setUploading('Uploading...');
			event.currentTarget.disabled = true;
			let datas = JSON.stringify({
			  "uuid": localStorage.getItem('uuid'),
			  "image": info.image,
			  "type": 'address',
			});
			let config = {
				  method: 'post',
				 'mode':'cors',
				  url: 'https://kyc2.codegotech.com/save_image',
				  headers: {
					'Content-Type': 'application/json'
				  },
				  data : datas
			};			
			axios.request(config).then((response) => {		  
			if(response.status===200)
			{
				//console.log(response);
				if(response.data.status === 1)
				{
										
					if(kyctype === 'partial' && partial_page === 'ADDRESS')
					{
						
						let data = JSON.stringify({
							"uuid":localStorage.getItem('uuid'),
							"kyctype": kyctype,							
							"partial_page": partial_page,							
							"userAgent": localStorage.getItem('userAgent'),							
							"ip_address": localStorage.getItem('Ip-Adrees'),							
							"proofphoto": response.data.image
						});
						let config = {
							  method: 'post',
							 'mode':'cors',
							  url: 'https://kyc2.codegotech.com/send_userdata',
							  headers: {
								'Content-Type': 'application/json'
							  },
							  data : data
						};						
						axios.request(config).then((response) => {
							//console.log(response.status);
							if(response.status === 200)
							{				
								localStorage.removeItem('userAgent');								
								localStorage.removeItem('Ip-Adrees');
								localStorage.removeItem('proofPhoto');								
								localStorage.removeItem('uuid');
								navigate('/process');			
							}		  
						}).catch((error) => {
							console.log(error);
						}); 
					}else{
						localStorage.setItem('proofPhoto', response.data.image);	
						navigate('/Recognition')
					}				
				}else{
					setVisible(false);
					let html = 'Please re-upload proof address.';
					let mrz_data_element = document.getElementById('mrz-data')
					mrz_data_element.innerHTML =  html
				}
			}		  
			})
			.catch((error) => {
			  console.log(error);
			});
		}
        
    
        useEffect(() => {
            let uuids = localStorage.getItem('uuid');
            if (uuids === null) {
                navigate('/');
            }
            getData();
        }, []);
        useEffect(() => {
       
          }, [isMobile]);
	return (
	<div className="container-fluid">
        {!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}

      {isMobile && (
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					

					<div className="card">
						<div className="card-header text-center">							
							<div className="title">Proof of Address</div>
							<div id='mrz-data'></div>						
							<small>Make sure that all the information on the photo is visible and easy to read</small>
						</div>							
						<div className="card-body text-center">
                        <div className="bg_camera proof">
                                    {idimageSrc ? (
                                        <div className="box-camera">
                                            <img src={idimageSrc} alt="Captured" className="img-fluid" />
											<p style = {{color: "red"}} >{uploading}</p>
											<div className="box-btnProof">			
                                                <button onClick={() => setImageSrc(null)} className="btn_retake">Retake</button>
                                                <button onClick={handleUpload} disabled={!idimageSrc} className="btn_retake upload">Submit</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <Webcam
                                                audio={false}
                                                ref={webcamRef}
                                                screenshotFormat="image/jpg"
                                                videoConstraints={{
                                                    height: 720, 
                                                    width: 1280,
                                                    facingMode: "environment"
                                                }}
                                                className="webcam proof"
												height={720}
                                                width={1280}                                               
												style = {{width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center"}}
                                               
                                            />
                                            <div className="circle_btn proof">
                                                <button onClick={capture} className="camera proof"></button>
                                            </div>
                                        </>
                                    )}
                                </div>
						</div>
					</div>
                    <div className="title">Proof of Address</div>
                    <div className="">
                        <ol className="list_proof">
                            <li>Utility Bill (less then 3 months)</li>
                            <li>Bank Account Statement(less then 3 months)</li>
                            <li>Certificate of residence (released by the municipality)</li>
                        </ol>
                    </div>
					<div className="row footer">	
						<div className="collect-steps_1">{brandname}</div>
					</div>
					
				</div>
			</div>
		</div>
        )}
	</div>
	);
}
export default ProffAdress;