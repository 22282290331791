import React ,{ useState ,useEffect  } from "react" ;
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './user.css';



const User = () => {

const [message, setMessage] = useState("");
const [btnhideshow, setBtnhideshow] = useState(false);
const brandname = localStorage.getItem('brandname');
const [inputtype, setInputtype] = useState(false);
const [disabledbtn, setdisabled] = useState(false);
const [btn , setbtn] = useState("btn btn-primary");
const [btntxt , setbtntxt] = useState("Continue");
const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
const [isMobile, setIsMobile] = useState(false);

const checkIfMobile = () => {
    if (window.innerWidth <= 768) {
      return true; 
    }
    return false;
  };

  const updateMedia = () => {
    setIsMobile(checkIfMobile());
  };
  useEffect(() => {
    updateMedia();
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, []);

const navigate = useNavigate();
	
	const handleChange = (event) => {
		if(event.target.value === 'newuser')
		{
			navigate('/personal');
		}else if(event.target.value === 'exist')
		{
			
			setInputtype(true);
		}
	}
	
	const handleChangeInput = (event) => {
		if(event.target.value !== '')
		{
			setBtnhideshow(true);
		}else{
			setBtnhideshow(false);
		}
	}
	const isalreadycheck = localStorage.getItem('isalreadycheck');	
	const nextpage = async ()=>{
		const document_number = document.getElementById("document_number").value;
		if(document_number !== '')
		{
			setdisabled(true);
			
			var uuid = localStorage.getItem('uuid');	
			
			let data = JSON.stringify({
				"uuid": uuid,
				"document_number": document_number,
			});		
			let config = {
				method: 'POST',
				mode: 'cors',	
				url: 'https://kyc2.codegotech.com/submitdoucment',
				headers: {
					'Content-Type': 'application/json',				
				},
				data : data
			};		
			await axios.request(config)
			.then((response) => {
				
				if(response.status == 200)
				{				
					if(response.data.status == 1)
					{		
						localStorage.setItem('isalreadycheck', 0);	
						localStorage.setItem('comImage',  response.data.comImage);
						localStorage.setItem('partial_page', 'LIVENESS');
						localStorage.setItem('kyctype', response.data.type);
						
						setTimeout(function(){	navigate('/kycalready') }, 500);	
						
					}else if(response.data.status == 0)
					{		
						setdisabled(false);
						setMessage(response.data.message);
					}else if(response.data.status == 3)
					{			
						
						setMessage(response.data.message);						
						setTimeout(function(){ navigate('/personal'); },5000);
					}else if(response.data.status == 4)
					{			
						
						setMessage(response.data.message);						
						setTimeout(function(){ navigate('/personal'); },5000);
					}else if(response.data.status == 2)
					{			
						
						setMessage(response.data.message);						
						setTimeout(function(){ 
							setMessage(''); 
						},5000);
					
						setTimeout(function(){ 
							setdisabled(false);
						},1000);
						
					}				
				}else{	
					setdisabled(false);
					setMessage('unauthorized access');
				} 
				
			}).catch((error) => {
				console.log(error);
			}); 
			
		}			
	}	
	useEffect(() => {
	let uuids = localStorage.getItem('uuid');	
	if(uuids === null) 
	{				
		navigate('/');
	}
	}, []);
	useEffect(() => {
	
	  }, [isMobile]);
    return (
	<div className="container-fluid">
		{!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
		  <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}
	  {isMobile && ( 
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					
					
					<div className="cards">
						<div className="card-header text-center">							
							<div className="title">Check If your kyc account has already been validated</div>
							<p></p>
						</div>
						
						<div className="card-body">
							<div className="select_div_label">
								<label htmlFor="exampleFormControlInput1" className="form-label text-danger">{message}</label>
							</div>
						</div>	
						<div className="card-body">
							<div className="select_div_label">
								<label htmlFor="exampleFormControlInput1" className="form-label">Select if you are a new user or if you already KYC Customer.</label>
								<div className="selectarea " >											
									<select id="type" onChange={handleChange} className="selectDrop">
										<option value="">Select Option</option>
										<option value="newuser">New User</option>
										<option value="exist">Already KYC Customer</option>
									</select>
								</div>	
							</div>	
							{inputtype  &&   <div className="select_div_label">
								<label htmlFor="exampleFormControlInput1" className="form-label">Enter Your Id Proof Number</label>
								<div className="selectarea" >											
									<input type="text" className="selectDrop" id="document_number" placeholder="Enter Id Proof Number" onChange={handleChangeInput}  />
								</div>									 
							</div>}							
							{btnhideshow  &&   <div className="select_btn home_btn" ><button className={btn} disabled={disabledbtn} onClick={nextpage} >{btntxt} <i className={btnicon}></i></button></div>}
						</div>
						
						<div className="row footer">	
							<div className="collect-steps_1">{brandname}</div>
						</div>					
					</div>
				</div>
			</div>		
		</div>
		 )}
	</div>
);
};
 
export default User;